import PermitForPageOnly from "components/PermitForPageOnly";
import SpinnerComponent from "components/Spinner";
import ProfilePage from "pages/ProfilePage";
import SupportLinks from "pages/SupportLinkPage";
import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Index = lazy(() => import("pages/index"));
const DashboardPage = lazy(() => import("pages/DashboardPage/index"));
const AnnouncementsPage = lazy(() => import("pages/AnnouncementsPage"));
const BannersPage = lazy(() => import("pages/BannersPage"));
const CategoriesPage = lazy(() => import("pages/CategoriesPage"));
const CouponCodePage = lazy(() => import("pages/CouponCodePage"));
const CouriersPage = lazy(() => import("pages/CouriersPage"));
const DropdownPage = lazy(() => import("pages/DropdownPage"));

const LocationsPage = lazy(() => import("pages/LocationsPage"));
const NewsPage = lazy(() => import("pages/NewsPage"));
const OrdersPage = lazy(() => import("pages/OrdersPage"));
const PaperOutPage = lazy(() => import("pages/PaperOutPage/index"));
const PaymentGatewayPage = lazy(() => import("pages/PaymentGatewayPage"));
const ReviewsPage = lazy(() => import("pages/ReviewsPage"));
const ReferralAccountPage = lazy(() => import("pages/ReferralAccountPage"));

const ProjectsPage = lazy(() => import("pages/ProjectsPage/index"));
const ProjectForm = lazy(() => import("pages/ProjectsPage/ProjectForm"));

const PropertiesPage = lazy(() => import("pages/PropertyPage"));
const PropertyForm = lazy(() => import("pages/PropertyPage/PropertyForm"));

const ContentPage = lazy(() => import("pages/ContentPage"));
const ContentPagesForm = lazy(() =>
  import("pages/ContentPage/ContentPagesForm")
);

const SaatBaarTeamPage = lazy(() => import("pages/SaatBaarTeam/index"));
const SaatBaarTeamForm = lazy(() =>
  import("pages/SaatBaarTeam/SaatBaarTeamForm")
);

const InquiryAnalyticsPage = lazy(() => import("pages/InquiryAnalyticsPage"));
const InquiryAnalyticsForm = lazy(() =>
  import("pages/InquiryAnalyticsPage/InquiryAnalyticsForm")
);

const SubscriptionPlanPage = lazy(() =>
  import("pages/SubscriptionPlanPage/index")
);
const SubscriptionPlanForm = lazy(() =>
  import("pages/SubscriptionPlanPage/SubscriptionPlanForm")
);

const SettingsPage = lazy(() => import("pages/SettingsPage"));
const SiteLinksPage = lazy(() => import("pages/SitelinksPage"));
const TPDPMapsPage = lazy(() => import("pages/TPDPMapsPage"));
const MyCompaniesPage = lazy(() => import("pages/MyCompanies/index"));
const PageNotFoundPage = lazy(() => import("pages/PageNotFoundPage"));

const Router = () => {
  return (
    <Suspense fallback={<SpinnerComponent />}>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route
          path={"/dashboard"}
          element={
            <PermitForPageOnly module="dashboard">
              <DashboardPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/myCompanies"}
          element={
            <PermitForPageOnly module="myCompanies">
              <MyCompaniesPage />
            </PermitForPageOnly>
          }
        />
      
        <Route
          path={"/profile/:id"}
          element={
            <PermitForPageOnly module="dashboard">
              <ProfilePage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/banners"}
          element={
            <PermitForPageOnly module="banners">
              <BannersPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/projects"}
          element={
            <PermitForPageOnly module="projects">
              <ProjectsPage />
            </PermitForPageOnly>
          }
        />

        <Route
          path={"/projects/new"}
          element={
            <PermitForPageOnly module="projects">
              <ProjectForm />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/projects/:id"}
          element={
            <PermitForPageOnly module="projects">
              <ProjectForm />
            </PermitForPageOnly>
          }
        />

        <Route
          path={"/properties"}
          element={
            <PermitForPageOnly module="properties">
              <PropertiesPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/properties/new"}
          element={
            <PermitForPageOnly module="properties">
              <PropertyForm />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/properties/:id"}
          element={
            <PermitForPageOnly module="properties">
              <PropertyForm />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/tpdpmaps"}
          element={
            <PermitForPageOnly module="tpdpmaps">
              <TPDPMapsPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/orders"}
          element={
            <PermitForPageOnly module="orders">
              <OrdersPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/news"}
          element={
            <PermitForPageOnly module="news">
              <NewsPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/paperOut"}
          element={
            <PermitForPageOnly module="paperOut">
              <PaperOutPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/supportlinks"}
          element={
            <PermitForPageOnly module="supportlinks">
              <SupportLinks />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/subscriptionplan"}
          element={
            <PermitForPageOnly module="subscriptionplan">
              <SubscriptionPlanPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/subscriptionplan/new"}
          element={
            <PermitForPageOnly module="subscriptionplan">
              <SubscriptionPlanForm />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/subscriptionplan/:id"}
          element={
            <PermitForPageOnly module="subscriptionplan">
              <SubscriptionPlanForm />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/locations"}
          element={
            <PermitForPageOnly module="locations">
              <LocationsPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/inquiryanalytics"}
          element={
            <PermitForPageOnly module="inquiryanalytics">
              <InquiryAnalyticsPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/inquiryanalytics/:id"}
          element={
            <PermitForPageOnly module="inquiryanalytics">
              <InquiryAnalyticsForm />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/announcements"}
          element={
            <PermitForPageOnly module="announcements">
              <AnnouncementsPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/couponcode"}
          element={
            <PermitForPageOnly module="couponcode">
              <CouponCodePage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/reviews"}
          element={
            <PermitForPageOnly module="reviews">
              <ReviewsPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/categories"}
          element={
            <PermitForPageOnly module="categories">
              <CategoriesPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/dropdown"}
          element={
            <PermitForPageOnly module="dropdown">
              <DropdownPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/contentpages"}
          element={
            <PermitForPageOnly module="contentpages">
              <ContentPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/contentpages/new"}
          element={
            <PermitForPageOnly module="contentpages">
              <ContentPagesForm />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/contentpages/:id"}
          element={
            <PermitForPageOnly module="contentpages">
              <ContentPagesForm />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/referralaccount"}
          element={
            <PermitForPageOnly module="referralaccount">
              <ReferralAccountPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/saatbaarteam"}
          element={
            <PermitForPageOnly module="saatbaarteam">
              <SaatBaarTeamPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/saatbaarteam/new"}
          element={
            <PermitForPageOnly module="saatbaarteam">
              <SaatBaarTeamForm />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/saatbaarteam/:id"}
          element={
            <PermitForPageOnly module="saatbaarteam">
              <SaatBaarTeamForm />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/paymentgateway"}
          element={
            <PermitForPageOnly module="paymentgateway">
              <PaymentGatewayPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/couriers"}
          element={
            <PermitForPageOnly module="couriers">
              <CouriersPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/settings"}
          element={
            <PermitForPageOnly module="settings">
              <SettingsPage />
            </PermitForPageOnly>
          }
        />
        <Route
          path={"/sitelinks"}
          element={
            <PermitForPageOnly module="sitelinks">
              <SiteLinksPage />
            </PermitForPageOnly>
          }
        />
        <Route path="*" element={<PageNotFoundPage />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
