import AWS from "aws-sdk";
import imageCompression from "browser-image-compression";
import apiHelper from "./apiHelper";
const { Buffer } = require("buffer");

AWS.config.update({
  accessKeyId: "AKIA6KC7QWKSMH3TI4U5",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: "saatbaar-media" },
  region: "us-east-2",
});

export const uploadMediaToS3Bucket = async (mediaArray) => {
  if (mediaArray.length <= 0) {
    return false;
  }
  try {

    const uploadPromises = mediaArray.map(async (media) => {
      try {

        const fileTypes = ['png', 'jpg', 'jpeg', 'webp', 'svg']
        const isImage = fileTypes.some(type => media.key.toLowerCase().includes(type));

        let body = media.file;

        if (isImage) {

          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            fileType: "image/webp",
          }

          body = await imageCompression(media.file, options);

        }

        let params = {
          ACL: "private",
          Bucket: "saatbaar-media",
          Key: media.key,
          ContentType: body.type,
          Body: body,
        };
        await myBucket.putObject(params).promise();
        return { success: true, key: media.key };
      } catch (error) {
        console.error(`Error uploading ${media.key}:`, error);
        return { success: false, key: media.key, error };
      }
    });

    const results = await Promise.allSettled(uploadPromises);

    const failedUploads = results
      .filter((result) => result.status === "fulfilled" && !result.value.success)
      .map((result) => result.value);

    const errors = results
      .filter((result) => result.status === "rejected")
      .map((result) => result.reason);

    if (failedUploads.length > 0 || errors.length > 0) {
      console.warn("Some uploads failed:", { failedUploads, errors });
    } else {
      console.log("All uploads successful");
    }

    return true; // Always return true to signal the process completed
  } catch (error) {
    console.error("Unexpected error during upload:", error);
    return false; // Error occurred during overall process
  }
};
export const deleteMediaFromS3Bucket = (key) => {
  if (key) {
    new Promise((resolve, reject) => {
      const params = {
        Bucket: "saatbaar-media",
        Key: key,
      };

      myBucket.deleteObject(params, (err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve()
        }
      });
    })

  }
};

export const applyWatermarkAndUpload = async (mediaArray) => {
  if (mediaArray.length <= 0) {
    return false;
  }

  const uploadPromises = mediaArray.map(async (media) => {
    try {
      const formData = new FormData();
      formData.append("image", media.file);
      formData.append("key", media.key);

      await apiHelper(`api/v1/media/upload_image_with_watermark`, "post", formData, {});
      console.log(`Successfully uploaded image with key: ${media.key}`);
      return { success: true, key: media.key };
    } catch (error) {
      console.error(`Error uploading image with key ${media.key}:`, error);
      return { success: false, key: media.key, error };
    }
  });

  const results = await Promise.allSettled(uploadPromises);

  const failedUploads = results.filter(
    (result) => result.status === "fulfilled" && !result.value.success
  );

  console.log("Upload results:", results);
  return results;
};


export const applyCenterWatermarkAndUpload = async (mediaArray) => {

  if (mediaArray.length <= 0) {
    return false;
  }

  try {
    mediaArray.forEach(async (media) => {
      const formData = new FormData();
      formData.append("image", media.file);
      formData.append("key", media.key);

      await apiHelper(`api/v1/media/upload_image_with_only_center_watermark`, "post", formData, {});

    });
  } catch (error) {
    console.error("Error uploading images to S3:", error);
  }
};