import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Spinner,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/store/hooks";
import {
  selectColorThemeMode,
  selectShowLoader,
  setLoader,
} from "stores/uiSlice";
import SelectInput from "components/Inputs/SelectInput";
import TextInput from "components/Inputs/TextInput";
import ImageUploading from "react-images-uploading";
import CloseIcon from "components/Icons/PropertyIcon/CloseIcon.svg";
import InfoIcon from "components/Icons/PropertyIcon/InfoIcon.svg";
import DefaultAvatar from "components/Icons/UserPageIcons/DefaultAvatar.png";
import CameraIcon from "components/Icons/UserPageIcons/CameraIcon.svg";
import DeleteIcon from "components/Icons/UserPageIcons/DeleteIcon.svg";
import LeftArrow from "components/Icons/Common/LeftArrow.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { USER_ACCOUNT_URLS } from "configs/usersData";
import {
  selectStateList,
  selectStatesDataLoaded,
  selectUserTypesList,
  selectUserTypesDataLoaded,
  statesListThunk,
  userTypesListThunk,
} from "stores/commonSlice";
import { getDistrictArray } from "api/common";
import { getSingleUser, saveUser } from "api/users";
import { useToast } from "@chakra-ui/react";
import { errorIdentifier } from "utils/errorHandler";
import {
  applyCenterWatermarkAndUpload,
  deleteMediaFromS3Bucket,
  uploadMediaToS3Bucket,
} from "services/awsHelper";
import PhoneInput from "react-phone-number-input";
import { selectUserDetails } from "stores/authSlice";

const ProfilePage = () => {
  const { id } = useParams();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const colorThemeMode = useAppSelector(selectColorThemeMode);
  const isStatesListLoaded = useAppSelector(selectStatesDataLoaded);
  const statesList = useAppSelector(selectStateList);
  const isUserTypesDataLoaded = useAppSelector(selectUserTypesDataLoaded);
  const userTypesList = useAppSelector(selectUserTypesList);
  const currentUserDetails = useAppSelector(selectUserDetails);

  const [userForm, setUserForm] = useState({
    profileImage: "",
    firstName: "",
    lastName: "",
    password: "",
    userName: "",
    companyName: "",
    coverImages: "",
    bio: "",
    mobileNumber: "",
    email: "",
    gstNumber: "",
    address: "",
    experience: "",
    state: {
      id: "",
      name: "",
    },
    city: {
      id: "",
      name: "",
    },
    userType: "",
    socialLinks: {
      websiteLink: "",
      facebook: "",
      instagram: "",
      twitter: "",
      youtube: "",
      telegram: "",
      messenger: "",
      whatsapp: "",
      linkedin: "",
      pintrest: "",
    },
    role: "merchantAdmin",
  });

  const [userProfileImage, setUserProfileImage] = useState("");
  const [tempProfileImage, setTempUserProfileImage] = useState("");
  const [showUserNameBorder, setUserNameBorder] = useState(false);
  let [selectedCoverImages, setSelectedCoverImages] = React.useState([]);
  let [tempCoverImages, setTempCoverImages] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [professionalsList, setProfessionalsList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [professionType, setProfessionType] = useState("Professional");
  const [userFormDataLoaded, setUserFormDataLoaded] = useState(false);

  useEffect(() => {
    if (!isStatesListLoaded) {
      dispatch(statesListThunk());
    }

    if (!isUserTypesDataLoaded) {
      dispatch(userTypesListThunk());
    }
  });

  useEffect(() => {
    if (isUserTypesDataLoaded) {
      const professionals = userTypesList.filter((userTypes) => {
        return userTypes.type === "Professionals";
      });
      setProfessionalsList(professionals);

      const services = userTypesList.filter((userTypes) => {
        return userTypes.type === "Services";
      });
      setServicesList(services);
    }
  }, [isUserTypesDataLoaded]);

  useEffect(() => {
    if (id) {
      getSingleUser(id).then((res) => {
        const user = res.data.data.data;

        setUserForm({
          profileImage: user.profileImage,
          firstName: user.firstName,
          lastName: user.lastName,
          userName: user.userName,
          companyName: user.companyName,
          coverImages: user.coverImages,
          bio: user.bio,
          mobileNumber: user.mobileNumber,
          email: user.email,
          gstNumber: user.gstNumber,
          address: user.address,
          state: {
            id: user.state.id,
            name: user.state.name,
          },
          city: {
            id: user.city.id,
            name: user.city.name,
          },
          userType: user.userType._id,
          socialLinks: {
            websiteLink: user.socialLinks.websiteLink,
            facebook: user.socialLinks.facebook,
            instagram: user.socialLinks.instagram,
            twitter: user.socialLinks.twitter,
            youtube: user.socialLinks.youtube,
            telegram: user.socialLinks.telegram,
            messenger: user.socialLinks.messenger,
            whatsapp: user.socialLinks.whatsapp,
          },
          role: user.role,
        });
        setProfessionType(user.userType.type);
        const tempImageList = user.coverImages.map((item) => {
          return {
            key: item,
            data_url: process.env.REACT_APP_IMAGE_API_URL + item,
          };
        });
        setSelectedCoverImages([...tempImageList]);
        setTempCoverImages([...user.coverImages]);
        if (user.profileImage !== "") {
          setUserProfileImage({
            key: user.profileImage,
            data_url: process.env.REACT_APP_IMAGE_API_URL + user.profileImage,
          });
          setTempUserProfileImage(user.profileImage);
        }
        setUserFormDataLoaded(true);
      });
    }
  }, []);
  useEffect(() => {
    if (userFormDataLoaded && userForm.state.id) {
      handleChangeStateValue(userForm.state.id);
    }
  }, [userFormDataLoaded]);

  const handleChangeUserFormData = (key, value) => {
    if (key === "firstName") {
      let userName =
        value.toLowerCase() + userForm.lastName.toLocaleLowerCase();
      setUserForm({ ...userForm, [key]: value, userName: userName });
    } else if (key === "lastName") {
      let userName =
        userForm.firstName.toLocaleLowerCase() + value.toLowerCase();
      setUserForm({ ...userForm, [key]: value, userName: userName });
    } else {
      setUserForm({ ...userForm, [key]: value });
    }
  };

  const handleNestedFormChange = (key, subKey, value) => {
    setUserForm({
      ...userForm,
      [key]: { ...userForm[key], [subKey]: value },
    });
  };

  // Property Image Upload OPeration
  const handleChangeProfileImageUpload = (imageList) => {
    setUserProfileImage(imageList[0]);
  };

  // Property Image Upload OPeration
  const handleChangeCoverImageUpload = (imageList) => {
    setSelectedCoverImages(imageList);
  };

  const handleChangeStateValue = (value) => {
    dispatch(setLoader(true));

    setCityList([]);

    getDistrictArray(value).then((res) => {
      setCityList(res.data.data);
      dispatch(setLoader(false));
    });
  };

  const saveUserForm = async () => {
    dispatch(setLoader(true));

    let isFormValid = true;

    const validationArray = [
      { name: "First Name", key: "firstName" },
      { name: "Last Name", key: "lastName" },
      { name: "User Name", key: "userName" },
      { name: "Mobile Number", key: "mobileNumber" },
      { name: "User Type", key: "userType" },
    ];

    for (const validationObject of validationArray) {
      if (userForm[validationObject.key] === "") {
        toast({
          title: "Error",
          description: `Please Enter ${validationObject.name}!`,
          status: "error",
          duration: 4000,
          variant: "subtle",
          isClosable: true,
        });
        isFormValid = false;
        dispatch(setLoader(false));
        break;
      }
    }
    if (!isFormValid) {
      return;
    }

    let S3MediaArray = [];
    let S3MediaArrayForWatermark = [];

    if (tempProfileImage !== "" && !userProfileImage.data_url) {
      deleteMediaFromS3Bucket(tempProfileImage);
      userForm.profileImage = "";
    } else if (userProfileImage.file) {
      if (tempProfileImage !== "") {
        deleteMediaFromS3Bucket(tempProfileImage);
      }
      let nameArray = userProfileImage.file.name.split(".");
      let key = `${nameArray[0]}_${Date.now()}.${
        nameArray[nameArray.length - 1]
      }`;
      S3MediaArray.push({ ...userProfileImage, key: key });
      userForm.profileImage = key;
    }

    selectedCoverImages = await Promise.all(
      selectedCoverImages.map(async (image) => {
        if (image.file) {
          let nameArray = image.file.name.split(".");
          let key = `${nameArray[0]}_${Date.now()}.webp`;
          S3MediaArrayForWatermark.push({ ...image, key: key });
          return { ...image, key: key };
        } else {
          tempCoverImages = tempCoverImages.filter(
            (item) => item !== image.key
          );
          return { ...image };
        }
      })
    );

    tempCoverImages.forEach((item) => {
      deleteMediaFromS3Bucket(item);
    });

    userForm.coverImages = selectedCoverImages.map((image) => image.key);
    if (!id) {
      userForm.password = Math.random().toString(36).slice(2, 10);
    }
    userForm.createdBy = currentUserDetails._id;

    saveUser(userForm, id)
      .then(async (res) => {
        navigate("/dashboard", { replace: true });
        dispatch(setLoader(false));
        uploadMediaToS3Bucket(S3MediaArray);
        applyCenterWatermarkAndUpload(S3MediaArrayForWatermark);
        toast({
          title: "Success",
          description: id
            ? "User Updated Succefully"
            : "User Created Successfully",
          status: "success",
          duration: 4000,
          variant: "subtle",
          isClosable: true,
        });
      })
      .catch((err) => {
        err.response.data.error.errorMessage === "Username already taken"
          ? setUserNameBorder(true)
          : setUserNameBorder(false);
        dispatch(setLoader(false));
        toast({
          title: "Error",
          description: errorIdentifier(err),
          status: "error",
          duration: 4000,
          variant: "subtle",
          isClosable: true,
        });
      });
  };

  return (
    <Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box display={"flex"} alignItems={"center"}>
          <Box as={Link} to={"/dashboard"}>
            <Image src={LeftArrow} mr={"10px"}></Image>
          </Box>
        </Box>
        <Box display={"flex"} alignItems={"center"}>
          <Button
            p={"12px 24px"}
            borderRadius={"30px"}
            fontSize={"18px"}
            fontWeight={500}
            border={"2px solid"}
            borderColor={`${colorThemeMode}_primary`}
            color={`${colorThemeMode}_primary`}
            lineHeight={"22px"}
            height={"46px"}
            onClick={() => navigate("/users", { replace: true })}
          >
            {" "}
            Discard{" "}
          </Button>
          <Button
            height={"46px"}
            ml={"10px"}
            p={"12px 24px"}
            borderRadius={"30px"}
            fontSize={"18px"}
            fontWeight={500}
            color={"#fff"}
            bgColor={`${colorThemeMode}_primary`}
            onClick={() => saveUserForm()}
          >
            {" "}
            Save
          </Button>
        </Box>
      </Box>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} mt={"32px"}>
        <Box
          bg={"#fff"}
          borderRadius={"12px"}
          boxShadow={"0px 8px 24px 0px rgba(0, 0, 0, 0.08)"}
          p={"24px"}
        >
          <Text variant={"primary18"} mb="24px">
            Profile Details
          </Text>
          {/* Profile Pictures & First Name & Last Name */}
          <Box mb={"24px"} display={"flex"} alignItems={"center"}>
            <Box minW={"100px"} mr={"16px"} position={"relative"}>
              <ImageUploading
                onChange={handleChangeProfileImageUpload}
                maxNumber={1}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageUpdate,
                  onImageRemove,
                }) => (
                  <>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      w={"96px"}
                      h={"96px"}
                      borderRadius={"full"}
                      border={"2px solid #1C5E3C"}
                      overflow={"hidden"}
                    >
                      <Image
                        minW={"170px"}
                        maxW={"170px"}
                        maxH={"170px"}
                        src={
                          userProfileImage.data_url
                            ? userProfileImage.data_url
                            : DefaultAvatar
                        }
                      />
                    </Box>
                    {userProfileImage && (
                      <Box
                        position={"absolute"}
                        w={"36px"}
                        h={"36px"}
                        p={"6px"}
                        borderRadius={"full"}
                        left={"0px"}
                        bottom={"0px"}
                        onClick={() => setUserProfileImage("")}
                        bgColor={`${colorThemeMode}_primary`}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        cursor={"pointer"}
                      >
                        <Image src={DeleteIcon} />
                      </Box>
                    )}
                    {!userProfileImage && (
                      <Box
                        position={"absolute"}
                        w={"36px"}
                        h={"36px"}
                        p={"6px"}
                        borderRadius={"full"}
                        right={"0px"}
                        bottom={"0px"}
                        onClick={onImageUpload}
                        bgColor={`${colorThemeMode}_primary`}
                        cursor={"pointer"}
                      >
                        <Image src={CameraIcon} />
                      </Box>
                    )}
                  </>
                )}
              </ImageUploading>
            </Box>
            <SimpleGrid columns={2} spacing={4}>
              <Box>
                <Text variant={"secondary14"} mb={"6px"}>
                  {" "}
                  First Name <span style={{ color: "red" }}>*</span>{" "}
                </Text>
                <TextInput
                  placeholder="Enter First Name"
                  value={userForm.firstName}
                  onChange={(e) =>
                    handleChangeUserFormData("firstName", e.target.value)
                  }
                />
              </Box>
              <Box>
                <Text variant={"secondary14"} mb={"6px"}>
                  Last Name <span style={{ color: "red" }}>*</span>
                </Text>
                <TextInput
                  placeholder="Enter Last Name"
                  value={userForm.lastName}
                  onChange={(e) =>
                    handleChangeUserFormData("lastName", e.target.value)
                  }
                />
              </Box>
            </SimpleGrid>
          </Box>

          {/* Password */}
          {/* {id ? <></> : <>
            <Box mb={"24px"}>
              <Text variant={"secondary14"} mb={"6px"}> Password <span style={{ color: 'red' }}>*</span></Text>
              <InputGroup size="md">
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={(e) => { handleChangeUserFormData('password', e.target.value) }}
                  value={userForm.password}
                  border={"1px solid #e1e1e1"}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    p={"0px"}
                    bg={"none"}
                    h="20px"
                    size="sm"
                    fontSize={"12px"}
                    onClick={() => setShowPassword(!showPassword)}
                    _hover={{}}
                    _active={{}}
                    color={"#818181"}
                  >
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <ForgotPasswordModal />
            </Box>
          </>} */}
          {/* User Name & Company Name */}
          <SimpleGrid columns={2} spacing={4} mb={"24px"}>
            <Box>
              <Text variant={"secondary14"} mb={"6px"}>
                {" "}
                User Name <span style={{ color: "red" }}>*</span>
              </Text>
              <TextInput
                placeholder="@username"
                value={userForm.userName}
                borderColor={`${showUserNameBorder ? "red" : ""}`}
                onChange={(e) =>
                  handleChangeUserFormData("userName", e.target.value)
                }
              />
            </Box>
            <Box>
              <Text variant={"secondary14"} mb={"6px"}>
                {" "}
                Company Name{" "}
              </Text>
              <TextInput
                placeholder="Please Enter Company Name"
                value={userForm.companyName}
                onChange={(e) =>
                  handleChangeUserFormData("companyName", e.target.value)
                }
              />
            </Box>
          </SimpleGrid>

          <SimpleGrid columns={2} spacing={4} mb={"24px"}>
            {/* Address */}
            <Box>
              <Text variant={"secondary14"} mb={"6px"}>
                {" "}
                Address{" "}
              </Text>
              <TextInput
                placeholder="Add Full Address"
                value={userForm.address}
                onChange={(e) =>
                  handleChangeUserFormData("address", e.target.value)
                }
              />
            </Box>

            {/* GST Number */}
            <Box>
              <Text variant={"secondary14"} mb={"6px"}>
                {" "}
                GST Number{" "}
              </Text>
              <TextInput
                placeholder="Add GST Number"
                value={userForm.gstNumber}
                onChange={(e) =>
                  handleChangeUserFormData("gstNumber", e.target.value)
                }
              />
            </Box>
          </SimpleGrid>

          <Box mb={"24px"}>
            <Text variant={"secondary14"} mb={"6px"}>
              {" "}
              Years Of Experience{" "}
            </Text>
            <TextInput
              placeholder="Enter Experience"
              value={userForm.experience}
              onChange={(e) =>
                handleChangeUserFormData("experience", e.target.value)
              }
            />
          </Box>

          {/* Cover Images (Size: 1024x1024) */}
          <Box mb={"24px"}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              mb={"6px"}
            >
              <Text fontSize={"14px"} color={"#BDBDBD"}>
                {" "}
                Cover Images{" "}
              </Text>
              <Image src={InfoIcon} />
            </Box>
            <ImageUploading
              multiple
              value={selectedCoverImages}
              onChange={handleChangeCoverImageUpload}
              maxNumber={10}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageUpdate,
                onImageRemove,
                dragProps,
              }) => (
                <Box
                  border={"1px solid #ccc"}
                  borderRadius={"8px"}
                  p={"8px"}
                  display={"flex"}
                  className="upload__image-wrapper"
                >
                  <Button
                    border={"1px solid #ccc"}
                    _focus={"1px solid #ccc "}
                    borderRadius={"8px"}
                    h={"80px"}
                    w={"80px"}
                    color={"#212121"}
                    fontSize={"24px"}
                    mr={"8px"}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    {" "}
                    +{" "}
                  </Button>

                  <Box
                    borderLeft={"1px solid #ccc"}
                    p={"0px 0px 0px 8px"}
                    display={"flex"}
                    alignItems={"center"}
                    flexWrap={"wrap"}
                    gap={"15px"}
                  >
                    {imageList.map((image, index) => (
                      <Box
                        key={index}
                        position={"relative"}
                        mr={"8px"}
                        minW={"12%"}
                      >
                        <Image
                          boxSize={"80px"}
                          src={image.data_url}
                          borderRadius={"8px"}
                        />
                        <Box
                          position={"absolute"}
                          top={"2px"}
                          right={"2px"}
                          bgColor={"#ffffff"}
                          borderRadius={"20px"}
                          onClick={() => onImageRemove(index)}
                        >
                          <Image src={CloseIcon} />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </ImageUploading>
          </Box>

          {/* Bio */}
          <Box mb={"24px"}>
            <Text variant={"secondary14"} mb={"6px"}>
              Bio
            </Text>
            <Textarea
              fontWeight={500}
              border={"1px solid #CCC"}
              _focusVisible={{ border: "1px solid #ccc" }}
              borderRadius={"8px"}
              placeholder="Please Enter Bio"
              value={userForm.bio}
              onChange={(e) => handleChangeUserFormData("bio", e.target.value)}
              size="sm"
            />
          </Box>

          {/* MobileNumber & Email */}
          <SimpleGrid columns={2} spacing={4} mb={"24px"}>
            {/* Mobile Number */}
            <Box>
              <Text variant={"secondary14"} mb={"6px"}>
                {" "}
                Mobile Number <span style={{ color: "red" }}>*</span>{" "}
              </Text>
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                className="phoneInput"
                placeholder="Enter phone number"
                defaultCountry="IN"
                style={{
                  border: "1px solid #CCC",
                  height: "44px",
                  borderRadius: "8px",
                }}
                value={userForm.mobileNumber}
                onChange={(value) =>
                  handleChangeUserFormData("mobileNumber", value)
                }
              />
            </Box>

            {/* Email */}
            <Box>
              <Text variant={"secondary14"} mb={"6px"}>
                {" "}
                Email{" "}
              </Text>
              <TextInput
                name="email"
                type="email"
                placeholder="Please Enter Email"
                value={userForm.email}
                onChange={(e) =>
                  handleChangeUserFormData("email", e.target.value)
                }
              />
            </Box>
          </SimpleGrid>

          {/* ProfessionType */}
          <Box mb={"24px"}>
            <RadioGroup
              onChange={(value) => {
                setProfessionType(value);
                handleChangeUserFormData(
                  "userType",
                  value === "Professionals"
                    ? professionalsList[0]._id
                    : servicesList[0]._id
                );
              }}
              value={professionType}
              colorScheme="green"
            >
              <Radio
                value="Professionals"
                border={"1px solid #ccc"}
                mr={"16px"}
              >
                <Text fontSize={"18px"} fontWeight={500}>
                  {" "}
                  Professional{" "}
                </Text>
              </Radio>
              <Radio value="Services" border={"1px solid #ccc"}>
                <Text fontSize={"18px"} fontWeight={500}>
                  {" "}
                  Product / Service{" "}
                </Text>
              </Radio>
            </RadioGroup>
          </Box>
          {professionType === "Professionals" ? (
            <Select
              fontWeight={500}
              border={"1px solid #CCC"}
              _focusVisible={{ border: "1px solid #ccc" }}
              cursor={"pointer"}
              borderRadius={"8px"}
              height={"44px"}
              value={userForm.userType}
              onChange={(e) => {
                handleChangeUserFormData("userType", e.target.value);
              }}
            >
              <option value={0}>
                Select{" "}
                {professionType === "Professionals"
                  ? "Professionals"
                  : "Product / Services"}
              </option>
              {professionalsList.map((item) => {
                return <option value={item._id}>{item.title}</option>;
              })}
            </Select>
          ) : (
            <Select
              fontWeight={500}
              border={"1px solid #CCC"}
              _focusVisible={{ border: "1px solid #ccc" }}
              cursor={"pointer"}
              borderRadius={"8px"}
              height={"44px"}
              value={userForm.userType}
              onChange={(e) => {
                handleChangeUserFormData("userType", e.target.value);
              }}
            >
              <option value={0}>
                Select{" "}
                {professionType === "Professional"
                  ? "Professional"
                  : "Product / Services"}
              </option>
              {servicesList.map((item) => {
                return <option value={item._id}>{item.title}</option>;
              })}
            </Select>
          )}

          {/* City & State */}
          <SimpleGrid columns={2} spacing={4} my={"24px"}>
            {/* State */}
            <Box>
              <Text variant={"secondary14"} mb={"6px"}>
                {" "}
                State{" "}
              </Text>
              <Select
                fontWeight={500}
                border={"1px solid #CCC"}
                _focusVisible={{ border: "1px solid #ccc" }}
                cursor={"pointer"}
                borderRadius={"8px"}
                height={"44px"}
                value={userForm.state.id}
                onChange={(e) => {
                  handleChangeStateValue(e.target.value);
                  handleChangeUserFormData("state", {
                    id: e.target.value,
                    name: e.target.options[e.target.selectedIndex].dataset.name,
                  });
                }}
              >
                <option value={0}>Select State</option>
                {statesList.map((state) => {
                  return (
                    <option value={state._id} data-name={state.stateName}>
                      {state.stateName}
                    </option>
                  );
                })}
              </Select>
            </Box>

            {/* District */}
            <Box>
              <Text variant={"secondary14"} mb={"6px"}>
                {" "}
                City{" "}
              </Text>
              <Select
                fontWeight={500}
                border={"1px solid #CCC"}
                _focusVisible={{ border: "1px solid #ccc" }}
                cursor={"pointer"}
                borderRadius={"8px"}
                height={"44px"}
                value={userForm.city.id}
                onChange={(e) =>
                  handleChangeUserFormData("city", {
                    id: e.target.value,
                    name: e.target.options[e.target.selectedIndex].dataset.name,
                  })
                }
              >
                <option value={0}>Select City</option>
                {cityList.map((city) => {
                  return (
                    <option value={city.id} data-name={city.districtName}>
                      {city.districtName}
                    </option>
                  );
                })}
              </Select>
            </Box>
          </SimpleGrid>
        </Box>
        {/* Right Side */}
        <Box
          bg={"#fff"}
          borderRadius={"12px"}
          boxShadow={"0px 8px 24px 0px rgba(0, 0, 0, 0.08)"}
          p={"24px"}
        >
          <Text variant={"primary18"} mb="24px">
            {" "}
            Social Links{" "}
          </Text>

          {/* Account URLs */}
          {USER_ACCOUNT_URLS.map((account, index) => {
            return (
              <Box mb={"24px"}>
                <Text fontSize={"14px"} color={"#BDBDBD"} mb={"6px"}>
                  {" "}
                  {account.title}{" "}
                </Text>
                <InputGroup
                  key={account.key}
                  border={"1px solid #ccc"}
                  _focusVisible={{ border: "1px solid #ccc" }}
                  alignItems={"center"}
                  borderRadius={"8px"}
                  mb={"6px"}
                >
                  <InputLeftElement
                    borderRadius={"8px"}
                    pointerEvents="none"
                    h={"44px"}
                  >
                    <Image src={account.icon}></Image>
                  </InputLeftElement>
                  <Input
                    type="url"
                    name={account.key}
                    fontWeight={500}
                    placeholder={`https://www.${account.key}.com/`}
                    height={"44px"}
                    borderRadius={"8px"}
                    value={userForm.socialLinks[account.key]}
                    onChange={(e) =>
                      handleNestedFormChange(
                        "socialLinks",
                        account.key,
                        e.target.value
                      )
                    }
                  ></Input>
                </InputGroup>
              </Box>
            );
          })}
        </Box>
      </SimpleGrid>
    </Box>
  );
};

export default ProfilePage;
